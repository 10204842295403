import styled from '@emotion/styled'
import { Link } from 'gatsby-plugin-react-intl'
import { FC } from 'react'

const ItemServiceUnpopularStyled = styled(Link)(({ theme }) => ({
    fontFamily: theme.fonts.default,
    color: theme.color.brand.secondary[900],
    position: 'relative',
    cursor: 'pointer',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    textDecoration: 'none',
    transition: 'all 0.5s ease',
    width: 'calc(25% - 48px)',
    minHeight: 160,
    boxSizing: 'border-box',
    margin: '0 24px',
    marginBottom: theme.spacing(5),
    background: theme.color.neutral.salt[100],
    padding: theme.spacing(3),
    borderRadius: theme.spacing(3),
    boxShadow: 'rgb(0 0 0 / 1%) 0px 20px 25px, rgb(0 0 0 / 1%) 0px 10px 10px',

    img: {
        width: '48px',
        marginBottom: theme.spacing(2),
        background: theme.color.brand.secondary[200],
        padding: theme.spacing(1),
        borderRadius: theme.spacing(3),
    },

    '&:hover': {
        h3: {
            //color: theme.color.semantic.info[900],
        },
    },

    '& h3': {
        transition: 'all 0.5s ease',
        fontSize: theme.spacing(2.2),
        fontWeight: 600,
        lineHeight: 'initial',
        margin: '0 0 10px 0',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        display: 'inline-block',
        maxWidth: '100%',
        //color: theme.color.semantic.info[500],
    },

    '& p': {
        fontSize: 14,
        margin: 0,
        lineHeight: '1.5',
        overflow: 'hidden',
        flexGrow: 1,
        color: theme.color.brand.secondary[800],
        WebkitLineClamp: 4,
        display: 'inline-block',
        WebkitBoxOrient: 'vertical',
        textOverflow: 'ellipsis',
        whiteSpace: 'normal',
    },

    '.online': {
        flexGrow: 0,
        padding: theme.spacing(1),
        borderRadius: theme.spacing(0.5),
        fontWeight: 'bold',
        background: theme.color.semantic.warning[200],
        marginBottom: theme.spacing(2),
    },

    [theme.breakpoints.down(theme.breakpoints.tablet)]: {
        width: `calc(33.33% - ${theme.spacing(6)})`,
    },
    [theme.breakpoints.down(theme.breakpoints.md)]: {
        width: `calc(50% - ${theme.spacing(6)})`,
    },
    [theme.breakpoints.down(theme.breakpoints.sm)]: {
        width: `calc(100% - ${theme.spacing(6)})`,
    },
}))

interface ItemServiceUnpopularProps {
    to: string
    title: string
    description: string
    icon?: string
    isOnline?: boolean
}

const ItemServiceUnpopular: FC<ItemServiceUnpopularProps> = ({
    to,
    description,
    title,
    icon = '',
    isOnline = false,
}) => {
    return (
        <ItemServiceUnpopularStyled to={to}>
            {icon && <img src={icon} alt={title} width={48} height={48} />}
            <h3>{title}</h3>
            {/*<ReactMarkdown>{description}</ReactMarkdown>*/}
            {isOnline && <p className="online">Online</p>}
        </ItemServiceUnpopularStyled>
    )
}

export default ItemServiceUnpopular
